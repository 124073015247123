import type { IconProps, IconTypes } from "solid-icons";
import { type Component, splitProps } from "solid-js";
import { Dynamic } from "solid-js/web";

export type StIconComponent = IconTypes;

/**
 * Storytell Icon
 */
export const StIcon: Component<IconProps & { icon: StIconComponent }> = (
  props,
) => {
  const [base, rest] = splitProps(props, ["icon"]);
  return <Dynamic component={base.icon} {...rest} classList={rest.classList} />;
};
